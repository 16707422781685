import React from 'react';
import logo from '../assets/images/Home/Navigation Brand.png';
import { Link } from 'react-router-dom';
import GetStartedModal from './GetStartedModal';
// import { CLOUDINARY_DOMAIN_ACCOUNT } from '../config';

function Navbar() {
  return (
    <div>
      <div className="">
        <nav class="navbar fixed-top navbar-expand-lg ">
          <div class="container">
            <span class="navbar-brand" >
              <div className="">
                <Link to="/">
                  <img
                    src={logo}
                    alt=""
                    className="logo"
                    width="50px"
                  />
                </Link>
              </div>
            </span>
            <button class="navbar-toggler p-0" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span class="navbar-toggler-icon m-0"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
              <ul class="navbar-nav ms-auto mb-2 mb-lg-0 ">
                <li class="nav-item dropdown">
                  <span
                    class="nav-link dropdown-toggle"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    What we do
                  </span>
                  <ul class="dropdown-menu">
                    <li>
                      <a href="/solutions">
                        <span class="dropdown-item">
                          Solutions
                        </span>
                      </a>
                    </li>
                  </ul>
                </li>
                <li class="nav-item dropdown">
                  <span
                    class="nav-link dropdown-toggle"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Who we are
                  </span>
                  <ul class="dropdown-menu">
                    <li>
                      <a href="/about">
                        <span class="dropdown-item">
                          About Us
                        </span>
                      </a>
                    </li>
                    <li>
                      <a href="/pathfinders">
                        <span class="dropdown-item">
                          Pathfinders
                        </span>
                      </a>
                    </li>
                    <li>
                      <a href="/partner">
                        <span class="dropdown-item">
                          Partners
                        </span>
                      </a>
                    </li>
                    <li>
                      <a href="/judge">
                        <span class="dropdown-item">
                          Judge
                        </span>
                      </a>
                    </li>
                    <li>
                      <a href="/security">
                        <span class="dropdown-item">
                          Security
                        </span>
                      </a>
                    </li>
                    <li>
                      <a href="/getintouch">
                        <span class="dropdown-item">
                          Get in touch
                        </span>
                      </a>
                    </li>
                    <li>
                      <a href="/faqs">
                        <span class="dropdown-item">
                          FAQs
                        </span>
                      </a>
                    </li>
                  </ul>
                </li>
                <li class="nav-item dropdown">
                  <span
                    class="nav-link dropdown-toggle"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Communities
                  </span>
                  <ul class="dropdown-menu">

                  </ul>
                </li>
                <li class="nav-item">
                  <span class="nav-link">
                    <a href="/events">Events</a>
                  </span>
                </li>
                <li class="nav-item">
                  <span class="nav-link">
                    <a href="/">Donate</a>
                  </span>
                </li>
              </ul>
              <GetStartedModal />
            </div>
          </div>
        </nav>

      </div>
    </div>
  );
}

export default Navbar;
