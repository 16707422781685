import React from 'react'
import Img3 from '../assets/images/connected/5500939.png'
function StayConectedEvent() {
    return (
        <>
            <div className="connected2">
                <div className="container">
                    <div className="row">
                        <div className="col-md-9">
                            <h2>Stay Connected</h2>
                            <p>Do not miss out on future AI Pathfinder events! Sign up for our newsletter or follow us on our social media handles to stay updated on upcoming events, workshops, and networking opportunities.</p>
                        </div>
                        <div className="col-md-2">
                            <img src={Img3} alt="" width="80%" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default StayConectedEvent