import React from 'react'
import LinkNav from '../../components/LinkNav'
import Event1 from '../../assets/images/event/20241006_221430.PNG'
import StayConectedEvent from '../../components/StayConnectedEvent'

function EventsPage() {
  return (
    <div>
      <div className="eventPage">
        <LinkNav
          FirstUrl="/company"
          First="Company"
          Dash="|"
          SecondUrl="/events"
          Second="Events"
        />
        <div className="container">
          <div className="headerText my-5">
            {/* <center> */}
            <h1 className='green'><b>Welcome to AI Pathfinder Events</b></h1>
            <p>At AI Pathfinder, we are committed to making artificial intelligence accessible and beneficial for everyone. Our events are designed to bring together diverse voices in the AI ecosystem: entrepreneurs, business owners, AI enthusiasts, and technology experts. Whether you are just starting your AI journey or looking to intensify your knowledge, our events offer something for everyone.</p>
            {/* </center> */}
          </div>
          <h2><b><i>Upcoming Featured Event:</i></b></h2>
          <div className="events p-4">
            <div className="row">
              <div className="col-md-5 mb-3">
                <img src={Event1} alt="" />
              </div>
              <div className="col-md-1"></div>
              <div className="col-md-5">
                <div className="eventDetails">
                  <span className='green'><b>October, 15th 2024</b></span>
                  <h1><b>AI on a Budget: Affordable Solutions for Small Businesses</b></h1>
                  <ul className='p-2'>
                    <li className=''><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-camera-video" viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M0 5a2 2 0 0 1 2-2h7.5a2 2 0 0 1 1.983 1.738l3.11-1.382A1 1 0 0 1 16 4.269v7.462a1 1 0 0 1-1.406.913l-3.111-1.382A2 2 0 0 1 9.5 13H2a2 2 0 0 1-2-2zm11.5 5.175 3.5 1.556V4.269l-3.5 1.556zM2 4a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h7.5a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1z" />
                    </svg> <span>Zoom Live</span></li>
                  </ul>
                  <p>Join us for an engaging discussion with David Oshame, a seasoned entrepreneur and AI enthusiast, who will explore how small businesses can utilize AI to bring about productivity, improve customer service, and scale without worries. Whether you are new to AI or looking to deepen your understanding, this event will provide practical, easy-to-implement strategies.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="margin50"></div>
          <div className="highlights">
            <h1><b>Highlights from Past Events:</b></h1>
            <p>At AI Pathfinder, our past events have offered immense information and connections for business owners, AI experts, and tech enthusiasts. If you missed out, check out some of the highlights:</p>
            <ul>
              <li><span className='green'>A Beginner’s Guide for Small Business Owners with AI</span> <br /><br /> This event broke down AI fundamentals for non-technical business owners, showing how easy-to-use AI tools can make a difference in daily operations. Attendees learned how AI could help save time, improve customer experiences, and reduce costs through automation.</li>
              <li><span className='green'>Building the Future: AI in Business Innovation</span> <br /><br /> A panel of AI experts and business leaders discussed the future of AI in different industries, emphasizing how AI is creating new entrepreneurial opportunities. Attendees left with a clearer </li>
            </ul>
          </div>
          <div className="margin50"></div>
          <StayConectedEvent />
        </div>
      </div>
    </div>
  )
}

export default EventsPage